import axios from "axios";
import { authHeader } from "helpers/AuthHeader";
import { handleResponse } from "helpers/HandleResponse";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem("user_info");
            window.location.reload(true);
        }
        return Promise.reject(error);
    }
);

export default class FeedSevice {
    urlGet = process.env.REACT_APP_WEBAPI_URL + "/api/feed";
    urlPost = process.env.REACT_APP_WEBAPI_URL + "/api/feed";

    get(portalid, userid, isActive) {
        const requestOptions = { headers: authHeader() };

        if (isActive === undefined) isActive = false;

        return new Promise((resolve, reject) => {
            axios
                .get(this.urlGet + "?portalid=" + portalid + "&userid=" + userid + "&active=" + isActive, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let items = res.data;
                    resolve(items);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    post(feedData) {
        const requestOptions = { headers: authHeader() };

        // check for update
        if (feedData.id > 0) {
            return new Promise((resolve, reject) => {
                axios
                    .put(this.urlPost + "/" + feedData.id, feedData, requestOptions)
                    .then(handleResponse)
                    .then((res) => {
                        let data = res.data;
                        resolve(data);
                    })
                    .catch((res) => {
                        reject("Error");
                    });
            });
        }

        // new item
        return new Promise((resolve, reject) => {
            let data = {
                name: feedData.name,
                url: feedData.url,
                description: feedData.description,
                isActive: feedData.isActive,
                userId: feedData.userId,
                isSystem: false,
            };

            if (feedData.isSystem) {
                data.isSystem = true;
                data.userId = "00000000-0000-0000-0000-000000000000";
            } else {
                data.isSystem = false;
            }

            axios
                .post(this.urlPost, data, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let data = res.data;
                    resolve(data);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    delete(feedData) {
        const requestOptions = { headers: authHeader() };

        return new Promise((resolve, reject) => {
            axios
                .delete(this.urlPost + "/" + feedData.id, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    resolve();
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    consume(userid) {
        const requestOptions = { headers: authHeader() };

        return new Promise((resolve, reject) => {
            axios
                .get(this.urlGet + "/consume/" + userid, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let items = res.data;
                    resolve(items);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }
}
