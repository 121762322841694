import axios from 'axios';
import { authHeader } from 'helpers/AuthHeader';
import { handleResponse } from 'helpers/HandleResponse';

axios.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === 401) {
            localStorage.removeItem('user_info');
            window.location.reload(true);
        }
        return Promise.reject(error);
   }
);

export default class MessageSevice {

    urlGet = process.env.REACT_APP_WEBAPI_URL + "/api/messageThread";
    urlPost = process.env.REACT_APP_WEBAPI_URL + "/api/messageThread";

    get(portalid, userid, isAdmin) {
        const requestOptions = { headers: authHeader() };
        
        return new Promise((resolve, reject) => {

            let url = (userid.length > 0 && !isAdmin)
                        ? this.urlGet + "?portalid=" + portalid + "&userid=" + userid
                        : this.urlGet + "?portalid=" + portalid;

            axios.get(url, requestOptions)
                .then(handleResponse)
                .then(res => {
                    let items = res.data;
                    resolve(items);
                })
                .catch(res => {
                    reject('Error');
                })
        })        
    }
    
    post(messageData) {
        const requestOptions = { headers: authHeader() };

        // check for update
        if(messageData.id > 0) {
            return new Promise((resolve, reject) => {
                axios.put(this.urlPost + "/" + messageData.id, messageData, requestOptions)
                    .then(handleResponse)
                    .then(res => {
                        let data = res.data;
                        resolve(data);
                    })
                    .catch(res => {
                        reject('Error');
                    })
            });       
        }

        // new item
        return new Promise((resolve, reject) => {
            
            axios.post(this.urlPost, messageData, requestOptions)
                .then(handleResponse)
                .then(res => {
                    let data = res.data;
                    resolve(data);
                })
                .catch(res => {
                    reject('Error');
                })
        }); 
    }

    postReply(messageData) {
        const requestOptions = { headers: authHeader() };

        // new item
        return new Promise((resolve, reject) => {
            
            axios.post(this.urlPost + "/message", messageData, requestOptions)
                .then(handleResponse)
                .then(res => {
                    let data = res.data;
                    resolve(data);
                })
                .catch(res => {
                    reject('Error');
                })
        }); 
    }

    delete(data) {

        // get ids in json
        let ids = JSON.stringify(data.map(o => o.id));
        
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: this.urlPost, 
                data: ids, 
                headers: authHeader() 
            })
            .then(handleResponse)
            .then(res => {                    
                resolve(data);
            })
            .catch(res => {
                reject('Error');
            });
        });
    }
}