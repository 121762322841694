import axios from "axios";
import { authHeader } from "helpers/AuthHeader";
import { handleResponse } from "helpers/HandleResponse";

// define public methods
export const userService = {
    getUserInfo,
    verify,
    get,
    getById,
    getUserProperties,
    updateSelf,
    post,
    put, // property update
    getUserRoles,
    addRoleToUser,
    deleteRoleFromUser,
    getAllRoles,
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem("user_info");
            window.location.reload(true);
        }
        return Promise.reject(error);
    }
);

// get the current user from local storage
function getUserInfo() {
    try {
        const u = localStorage.getItem("user_info") && JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user_info"))));
        return u;
    } catch (err) {
        console.log(err);
        localStorage.removeItem("user_info");
        return null;
    }
}

function verify(alias, idToken) {
    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/verify?alias=` + alias + "&token=" + idToken + "&context=fitpost";

        axios
            .get(url)
            .then(handleResponse)
            .then((res) => {
                let items = res.data;
                resolve(items);
            })
            .catch((res) => {
                reject(res);
            });
    });
}

function get(portalid, keyword, page, pagesize, sorted, roleid) {
    const requestOptions = { headers: authHeader() };
    // console.log(arguments);
    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user?portalid=${portalid}`;

        if (keyword.length > 0) {
            url += "&keyword=" + escape(keyword);
        }

        if (roleid > 0) url += "&roleid=" + roleid;

        if (page > 0 && pagesize > 0) url += "&page=" + page + "&pagesize=" + pagesize;

        if (sorted.length > 0) {
            url += "&sort=" + sorted + "&desc=false";
        }

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getById(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/` + id;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getUserProperties(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/${id}/properties`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function updateSelf() {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/me`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // copy over the token
                res.data.token = oldUser.token;

                // update the user in local storage with the new data
                localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));

                resolve();
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function post(data) {
    const requestOptions = { headers: authHeader() };

    // add new
    if (data.id === 0) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_WEBAPI_URL}/api/user`, data, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let post = res.data;
                    resolve(post);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    // update
    return new Promise((resolve, reject) => {
        axios
            .put(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + data.id, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                resolve(data);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

/*
    // update one field in the user record
    Inputs:
        userid
        data: {
            name,
            systemName,
            value, 
            fieldType
        }
*/
function put(userid, data) {
    const requestOptions = { headers: authHeader() };

    const d = {
        [data.systemName]: data.value,
    };

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_WEBAPI_URL}/api/user/${userid}/properties`, d, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // console.log("res", res);

                // get the stored user
                let oldUser = userService.getUserInfo();
                let idx = oldUser.properties.findIndex((o) => o.name === data.systemName);
                if (idx >= 0) oldUser.properties[idx].value = data.value;

                // update property
                // console.log(oldUser);

                // copy over the token
                //res.data.token = oldUser.token;

                // update the user in local storage with the new data
                localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(oldUser))));

                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getUserRoles(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/` + id + `/roles`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function addRoleToUser(userId, roleId, startDate, expiryDate) {
    const requestOptions = { headers: authHeader() };

    let data = {
        userId,
        roleId,
        startDate,
        expiryDate,
    };

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + userId + `/role`, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // if user is me, then update userinfo
                if (oldUser.id === userId) {
                    // copy over the token
                    res.data.token = oldUser.token;

                    // update the user in local storage with the new data
                    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));
                }

                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function deleteRoleFromUser(userId, roleId) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios
            .delete(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + userId + `/role/${roleId}`, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // if user is me, then update userinfo
                if (oldUser.id === userId) {
                    // copy over the token
                    res.data.token = oldUser.token;

                    // update the user in local storage with the new data
                    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));
                }

                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getAllRoles() {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/role`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}
