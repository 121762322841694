import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';

const Manage = ({match}) => (    
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/default`}/>
            <Route path={`${match.url}/default`} component={asyncComponent(() => import('./routes/Default'))}/>
            <Route path={`${match.url}/content/create`} component={asyncComponent(() => import('./routes/Content/create'))}/>
            <Route path={`${match.url}/content`} component={asyncComponent(() => import('./routes/Content'))}/>
            <Route path={`${match.url}/saved`} component={asyncComponent(() => import('./routes/SavedContent'))}/>
            <Route path={`${match.url}/feeds`} component={asyncComponent(() => import('./routes/Feeds'))}/>
            <Route path={`${match.url}/tags`} component={asyncComponent(() => import('./routes/Tags'))}/>
            <Route path={`${match.url}/settings`} component={asyncComponent(() => import('./routes/Settings'))}/>
            <Route path={`${match.url}/messages`} component={asyncComponent(() => import('./routes/Messages'))}/>
            <Route path={`${match.url}/announcements/announcement/:id?`} component={asyncComponent(() => import('./routes/Announcements/announcement'))}/>
            <Route path={`${match.url}/announcements`} component={asyncComponent(() => import('./routes/Announcements'))}/>
            <Route path={`${match.url}/users`} component={asyncComponent(() => import('./routes/Users'))}/>
            <Route path={`${match.url}/reports`} component={asyncComponent(() => import('./routes/Reports'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Manage;