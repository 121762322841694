import React from "react";
import IntlMessages from "util/IntlMessages";
import { NavLink } from "react-router-dom";
import { userService } from "services/UserService.js";

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.authUser = userService.getUserInfo();
    }

    userSignOut(e) {
        localStorage.removeItem("user_info");
        window.location.href = "//www.ideafit.com/wp-login.php?action=logout";
        e.preventDefault();
        return false;
    }

    render() {
        return (
            <div>
                <div className="user-profile">
                    <img className="user-avatar border-0 size-40" src={this.authUser.thumbnailUrl} alt="User" />
                    <div className="user-detail ml-2">
                        <h4 className="user-name mb-0">{this.authUser.fullName}</h4>
                        <small>{this.authUser.accountType}</small>
                    </div>
                </div>
                <a className="dropdown-item text-muted" href="https://www.ideafit.com/wp-admin/profile.php">
                    <i className="zmdi zmdi-face zmdi-hc-fw mr-1" />
                    <IntlMessages id="popup.profile" />
                </a>
                <NavLink to="/app/settings/default" className="dropdown-item text-muted">
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-1" />
                    <IntlMessages id="popup.setting" />
                </NavLink>
                <a className="dropdown-item text-muted" href="/" onClick={this.userSignOut}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
                    <IntlMessages id="popup.logout" />
                </a>
            </div>
        );
    }
}

export default UserInfo;
