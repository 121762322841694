import React, { useEffect, useState } from "react";
import { twitterService } from "services/TwitterService";
import qs from "qs";

export default function TwitterAuth() {
    const [errorMessage, setErrorMessage] = useState("");
    const values = qs.parse(window.location.search.replace("?", ""));
    console.log(values);
    /*const code = values.code;
    const state = values.state;*/
    const { oauth_token, oauth_verifier } = values;

    useEffect(() => {
        if (oauth_token && oauth_verifier) {
            twitterService
                .authenticate(oauth_token, oauth_verifier)
                .then((response) => {
                    // redirect to the settings page
                    window.location.href = "/app/settings/default";
                })
                .catch((error) => {
                    setErrorMessage("An error occurred");
                });
        } else {
            setErrorMessage("Invalid request");
        }
    }, []);

    return (
        <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
            <div className="page-error-content">
                <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">Connecting to Twitter...</h2>

                {errorMessage && <div>{errorMessage}</div>}
            </div>
        </div>
    );
}
