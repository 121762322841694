import { userService } from "../services/UserService";

// whenever the user needs a refresh(new social settings)
export function updateSelf() {
    userService.updateSelf().catch((err) => {
        console.log(err);
    });
}

// coming from the login (oauth.js)
export function setUser(user) {
    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(user))));
}

export function getPropValue(props, name) {
    if (!Array.isArray(props)) return "";
    const p = props.find((o) => o.name === name);
    if (p === undefined) return "";
    return p.value;
}
