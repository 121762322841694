export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user_info"))));

    if (user && user.token) {
        return {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
        };
    } else {
        return { "Content-Type": "application/json" };
    }
}
