export function handleResponse(response) {
    
    if (response.status.toString().substring(0,1) !== "2") {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('user_info');
            window.location.reload(true);
        }
        
        const error = (response.data && response.message) || response.statusText;
        return Promise.reject(error);
    }

    return response;    
}