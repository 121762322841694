import React from "react";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress";
import { userService } from "services/UserService";
import FeedService from "services/FeedService";
import qs from "qs";
import { LOGIN_URL } from "./LoginConfig";
import { setUser } from "util/UserUtils";

const feedService = new FeedService();

class Oauth extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            errReason: "",
        };
    }

    componentDidMount() {
        // make sure we have the id_token
        let q = qs.parse(window.location.search.replace("?", ""));

        // if no value, then go home
        if (q.code === "") this.props.history.push("/");

        const alias = window.location.port ? window.location.hostname + ":" + window.location.port : window.location.hostname;

        // get the user's info
        userService
            .verify(alias, q.code)
            .then((data) => {
                this.handleLogin(data);
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    errReason: err.response,
                });
            });
    }

    handleLogin(data) {
        this.setState({
            isloading: true,
        });

        setUser(data);

        // update feeds
        feedService
            .consume(data.id)
            .then((res) => {
                this.setState({
                    isloading: false,
                });

                // if new user, then go to welcome page

                if (data.isNewUser) window.location.hash = "/app/settings/welcome";
                else window.location.href = "/"; // go home
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <div className="mt-3 app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    {this.state.loading ? (
                        <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                            <div className="page-error-content">
                                <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                                    <a href={LOGIN_URL}>Logging you in...</a>
                                </h2>

                                <CircularProgress />
                            </div>
                        </div>
                    ) : (
                        <div className="p-3">
                            <div className="app-login-header mb-4">
                                <h1>
                                    <IntlMessages id="noaccess.title" />
                                </h1>
                            </div>

                            <div className="app-login-form">
                                <div className="bg-danger text-white p-2 text-center mb-3">
                                    <i className="zmdi zmdi-block"></i>&nbsp;
                                    <strong>
                                        <IntlMessages id="noaccess.blurb" />
                                    </strong>
                                    <br />
                                    Reason: {this.state.errReason}
                                </div>

                                <div className="row mt-3">
                                    <div className="col-6 text-center mt-3">
                                        <p>
                                            <a href="mailto://contact@ideafit.com" className="btn btn-primary">
                                                <IntlMessages id="noaccess.ideafit" />
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-6 text-center mt-3">
                                        <p>
                                            <a href={LOGIN_URL} className="btn btn-light">
                                                <IntlMessages id="noaccess.button" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Oauth;
