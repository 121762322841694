import React from 'react';
import NotificationItem from "./NotificationItem";
import CustomScrollbars from '../../util/CustomScrollbars';
import MessageService from '../../services/MessageService';

const messageService = new MessageService();

/*
const MailNotification = () => {
    return (
        <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
            <ul className="list-unstyled">
                {notifications.map((notification, index) => <NotificationItem key={index}
                                                                              notification={notification}/>)}
            </ul>
        </CustomScrollbars>
    )
};

export default MailNotification;
*/

export default class MailNotification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isloading: false,
            notifications: []
        };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState ({
            isloading: true
        });

        // figure out if we need to show specific user's msgs or admin level
        messageService.get(this.props.user.portalId, this.props.user.id, this.props.isAdmin)
            .then(data => {
                this.setState ({
                    isloading: false,
                    notifications: data
                });
            })
            .catch(err => console.log(err));
    }

    render() {
        return (
            <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
                <ul className="list-unstyled">
                    {this.state.notifications.map((notification, index) => <NotificationItem key={index}
                                                                                  notification={notification}/>)}
                </ul>
            </CustomScrollbars>
        );
    }
}
