import axios from "./AxiosHelper";

// define public methods
export const twitterService = {
    getAuthorizationUrl,
    authenticate,
};

function getAuthorizationUrl() {
    return axios.getData(`/api/social/twitter/authorizeurl`, true);
}

function authenticate(oauth_token, oauth_verifier) {
    const args = {
        oauth_token,
        oauth_verifier,
    };
    return axios.postData(`/api/social/twitter/authenticate`, args, true);
}
