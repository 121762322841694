import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './containers/App';
// import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './registerServiceWorker';
import 'antd/dist/antd.css';
import "./sass/index.scss";
import "./styles/app.scss";
// import './index.css';

ReactDOM.render(
    <BrowserRouter>
        <Route path="/" component={App} />        
    </BrowserRouter>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
