import React from 'react';
import {Route, Switch} from 'react-router-dom';
//import Header from '../components/Header/index';
//import Sidebar from 'containers/SideNav/index';
import Layout from 'components/Layouts/MasterLayout';

import Dashboard from './routes/dashboard';
import Brands from './routes/brands';
import Calendar from './routes/calendar';
import Content from './routes/content';
import Posts from './routes/posts';
import SavedContent from './routes/savedcontent';
import Settings from './routes/settings';
import Stats from './routes/stats';
import Preview from './routes/preview';
import Manage from './routes/manage';

import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from 'util/asyncComponent';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: true
        }
    }

    // handle sidenav hide/show toggle
    onToggleSideNav = (boo) => {
        let o = (boo !== undefined) ? boo : !this.state.navOpen;
        this.setState({
            navOpen: o
        })
    };

    render() {
        const {match} = this.props;

        // HACKY -> if not /app/content/default or /app/posts/post, dump the filter (would like to remove this)
        if(this.props.location.pathname !== "/app/content/default" && this.props.location.pathname !== "/app/posts/post")
            localStorage.removeItem("filter");

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        return (
            <Layout>

                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        
                        <Switch>
                            <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                            <Route path={`${match.url}/brands`} component={Brands}/>
                            <Route path={`${match.url}/calendar`} component={Calendar}/>
                            <Route path={`${match.url}/posts`} component={Posts}/>
                            <Route path={`${match.url}/content`} component={Content}/>
                            <Route path={`${match.url}/savedcontent`} component={SavedContent}/>
                            <Route path={`${match.url}/settings`} component={Settings}/>
                            <Route path={`${match.url}/stats`} component={Stats}/>
                            <Route path={`${match.url}/preview`} component={Preview}/>
                            <Route path={`${match.url}/manage`} component={Manage}/>                                    
                            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                        </Switch>
                        
                        <NotificationContainer/>
                    </div>
                </main>
                
            </Layout>
        );
    }
}

export default App;