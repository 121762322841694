import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { userService } from 'services/UserService.js';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from "util/CustomScrollbars";


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.authUser = userService.getUserInfo();
    }
        
    componentDidMount() {
        this.updateSelected();

        // listen to nav updates
        this.unlisten = this.props.history.listen((location, action) => {
            
            // unselect anything
            const opens = document.querySelectorAll('.nav-menu .open');            
            for (let i = 0; i < opens.length; i++) {
                opens[i].classList.remove('open');
            }

            // select the new one
            this.updateSelected();
        });
    }
    
    componentWillUnmount() {
        this.unlisten();
    }

    updateSelected() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        // catch submenu clicks
        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        // toggle main nav items in case they have a sub-menu
        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }


        const activeLink = document.querySelector('.menu > a[href="' + pathname + '"]');// select current a element        
        try {
            const activeNav = this.closest(activeLink, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) { // for subnav
                this.closest(activeNav, 'li').classList.add('open');
            } else { // for primary nav
                this.closest(activeLink, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;                
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className="scrollbar" style={{height: 'calc(100vh - 70px)'}}>
                <ul className="nav-menu">

                    <li className="nav-header"><IntlMessages id="sidebar.pages"/></li>

                    <li className="menu no-arrow joyride-dashboard">
                        <NavLink to="/app/dashboard/default">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.dashboard"/></span>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow joyride-schedule">
                        <NavLink to="/app/posts/default">
                            <i className="zmdi zmdi-time zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.schedule"/></span>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/calendar/default">
                            <i className="zmdi zmdi-calendar zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.calendar"/></span>
                        </NavLink>
                    </li>


                    
                    <li className="nav-header"><IntlMessages id="sidebar.posts"/></li>

                    <li className="menu no-arrow joyride-post">
                        <NavLink to="/app/posts/post">
                            <i className="zmdi zmdi-plus-square zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.post"/></span>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow joyride-content">
                        <NavLink to="/app/content/default">
                            <i className="zmdi zmdi-layers zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.library"/></span>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/savedcontent/default">
                            <i className="zmdi zmdi-favorite zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.saved"/></span>
                        </NavLink>
                    </li>
                    

                    <li className="nav-header"><IntlMessages id="sidebar.stats"/></li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/stats/default">
                            <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.stats.results"/></span>
                        </NavLink>
                    </li>

                    <li className="nav-header"><IntlMessages id="sidebar.settings"/></li>
                    <li className="menu no-arrow joyride-account">
                        <NavLink to="/app/settings/default">
                            <i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-lg"/>
                            <span className="nav-text"><IntlMessages id="sidebar.account"/></span>
                        </NavLink>
                    </li>
                </ul>

                {
                    this.authUser.fitPostAdminAccess && 
                    <ul className="nav-menu">
                        <li className="nav-header"><IntlMessages id="sidebar.administration"/></li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/default">
                                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/content">
                                <i className="zmdi zmdi-library zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.content"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/saved">
                                <i className="zmdi zmdi-bookmark zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.saved"/></span>
                            </NavLink>
                        </li>
                        { /* 
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/settings">
                                <i className="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.settings"/></span>
                            </NavLink>
                        </li>
                        */}
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/feeds">
                                <i className="zmdi zmdi-view-list zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.feeds"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/tags">
                                <i className="zmdi zmdi-tag zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.tags"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/messages">
                                <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.messages"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/announcements">
                                <i className="zmdi zmdi-alert-polygon zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.announcements"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/users">
                                <i className="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.users"/></span>
                            </NavLink>
                        </li>

                        <li className="nav-header"><IntlMessages id="sidebar.administration.reports"/></li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/stats">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.stats"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/posts-per-day">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.postsperday"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/popular-content">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.popularcontent"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/popular-favorites">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.popularfavorites"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/popular-tags">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.populartags"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/popular-platforms">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.popularplatforms"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/posts-detail">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.postsdetail"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/upcoming">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.upcoming"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/users-inactive">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.usersinactive"/></span>
                            </NavLink>
                        </li>
                        <li className="menu no-arrow">
                            <NavLink to="/app/manage/reports/feed-usage">
                                <i className="zmdi zmdi-chart zmdi-hc-fw zmdi-hc-lg"/>
                                <span className="nav-text"><IntlMessages id="sidebar.manage.reports.feedusage"/></span>
                            </NavLink>
                        </li>

                        <li className="menu no-arrow">
                            &nbsp;
                        </li>
                    </ul>
                }                
            </CustomScrollbars>
        );
    }
}

export default withRouter(Navigation);
