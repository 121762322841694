import React from "react";
import { UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Dropdown, Avatar, Drawer, Modal } from "antd";
import { withRouter, NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import Navigation from "./Navigation.js";
import { userService } from "services/UserService.js";
import UserInfo from "../UserInfo";
import MailNotification from "../MailNotification/index";
import { announcementService } from "services/AnnouncementService.js";
import Cookies from "universal-cookie";

const { Content, Header, Sider } = Layout;
const cookies = new Cookies();

class MasterLayout extends React.Component {
    constructor(props) {
        super(props);

        this.authUser = userService.getUserInfo();

        this.state = {
            collapsed: false,
            drawerVisible: false,
            announcement: {},
        };
    }

    componentDidMount() {
        // check for announcements
        let showAnnouncements = cookies.get("announcements") === undefined;

        // don't show if already seen today
        if (!showAnnouncements) return;

        let d = new Date();
        d.setTime(d.getTime() + 1440 * 30 * 1000); // 1/2 day
        cookies.set("announcements", "1", { path: "/", expires: d });

        // already seen these
        let r = localStorage.getItem("announcements")?.split(",") || [];

        announcementService
            .getActive({ portalId: this.authUser.portalId, ids: r })
            .then((data) => {
                if (data.id !== undefined) {
                    this.setState({
                        announcement: data,
                    });

                    // if it's not recurring, save it to localstorage
                    if (!data.isRecurring) {
                        r.push(data.id);
                        localStorage.setItem("announcements", r);
                    }

                    // show the modal
                    Modal.info({
                        okText: "close",
                        maskClosable: true,
                        content: (
                            <span>
                                <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
                                {data.url && (
                                    <div>
                                        <br />
                                        <a href={`${data.url}`} className="ant-btn ant-btn-secondary" target="blank">
                                            {data.urlText}
                                        </a>
                                    </div>
                                )}
                            </span>
                        ),
                    });
                }
            })
            .catch((err) => {
                localStorage.removeItem("announcements");
            });
    }

    toggle = () => {
        if (window.innerWidth >= 992)
            this.setState({
                collapsed: !this.state.collapsed,
            });
        else
            this.setState({
                drawerVisible: !this.state.drawerVisible,
            });
    };

    resetJoyride = () => {
        localStorage.removeItem("tutorial");
        this.props.history.push("/app/dashboard/default");
    };

    render() {
        let msgMenu = (
            <div className="align-items-center">
                <strong>
                    <IntlMessages id="mailNotification.title" />
                </strong>
                <br />
                <NavLink to="/app/settings/messages">
                    <span className="nav-text">
                        <IntlMessages id="nav.notifications" />
                    </span>
                </NavLink>
                <hr />
                <MailNotification user={this.authUser} />
            </div>
        );

        let thumbnail = this.authUser.thumbnailUrl !== "" ? <Avatar size={30} src={this.authUser.thumbnailUrl} /> : <Avatar size={30} icon={<UserOutlined />} class="light" />;

        let alertMessage = "";

        return (
            <Layout className="wrapper">
                <Drawer
                    title={
                        <NavLink to={"/"} className="navbar-logo-container">
                            <img src="https://www.ideafit.com/wp-content/uploads/2023/07/idea-logo-light.svg" className="img-fluid w-80" alt="FitPost" />
                        </NavLink>
                    }
                    placement="left"
                    closable={false}
                    onClose={this.toggle}
                    width={300}
                    visible={this.state.drawerVisible}
                >
                    <Navigation />
                </Drawer>

                <Sider
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    collapsedWidth="0"
                    breakpoint="lg"
                    width={220}
                    onBreakpoint={(broken) => {
                        this.setState({ collapsed: broken });
                    }}
                >
                    <Navigation />
                </Sider>
                <Layout>
                    <Header className="navbar">
                        <NavLink to={"/"} className="navbar-logo-container">
                            <img src="https://www.ideafit.com/wp-content/uploads/2023/07/idea-logo-light.svg" className="img-fluid w-80" alt="FitPost" />
                        </NavLink>

                        <span className="trigger" onClick={this.toggle}>
                            {this.state.collapsed && <MenuUnfoldOutlined />}
                            {!this.state.collapsed && <MenuFoldOutlined />}
                        </span>

                        <div className="ml-auto mr-0">
                            <span onClick={this.resetJoyride}>Take a Tour</span>
                        </div>
                        <div className="ml-3 mr-0">
                            <Dropdown trigger={["click"]} overlay={<UserInfo />}>
                                {thumbnail}
                            </Dropdown>
                        </div>
                        <div className="ml-3 mr-0">
                            <Dropdown trigger={["click"]} overlay={msgMenu}>
                                <span className="icon-btn size-20 font-size-14">
                                    <i className={`zmdi zmdi-comment-alt-text zmdi-hc-lg ${alertMessage}`} />
                                </span>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content className="container-fluid">{this.props.children}</Content>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(MasterLayout);
