import React from 'react';
import { Link } from 'react-router-dom'
import { LOGIN_URL } from "./LoginConfig";
import { userService } from 'services/UserService.js';
import CircularProgress from 'components/CircularProgress'


class ThirdPartySignIn extends React.Component {
    constructor(props) {
        super(props);

        // see if they have an account        
        this.authUser = userService.getUserInfo();

        if (this.authUser === null) {
           window.location.href = LOGIN_URL;
        }
    }


    render () {
        if(this.authUser === null) {
            return (
                <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                    <div className="page-error-content">                    
                        <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                            <a href={LOGIN_URL}>Forwarding to the sign in screen...</a>
                        </h2>
                        
                        <CircularProgress />
                    </div>
                </div>     
            );
        }
        else {
            return (
                <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                    <div className="page-error-content">                    
                        <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                            You're already signed in.
                        </h2>
                        
                        <p className="text-center zoomIn animation-delay-20 animated">
                            <Link className="btn btn-primary" to="/">Go Home</Link>
                        </p>
                    </div>
                </div>     
            );
        }
    }
}

export default ThirdPartySignIn;