import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import "react-toggle-switch/dist/css/switch.min.css";
import AppLocale from "../lngProvider";

import MainApp from "app/index";
import Oauth from "./Oauth";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ThirdPartySignin from "./ThirdPartySignin";
import TwitterAuth from "./TwitterAuth";
import asyncComponent from "util/asyncComponent";
import { userService } from "services/UserService.js";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/thirdpartysignin",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

class App extends Component {
    componentDidMount() {
        // if dev, then add class to change the bg color
        if (window.location.href.indexOf("localhost") > -1) document.getElementById("body").className += " body-dev";
    }

    render() {
        // get authUser from localStorage
        let authUser = userService.getUserInfo();
        // console.log("user", authUser);

        const { match, location } = this.props;
        if (location.pathname === "/") {
            if (authUser === null) {
                // if we have an id_token, then they are trying to login
                if (window.location.search.indexOf("code") > -1) {
                    return <Redirect to={`/oauth${window.location.search}`} />;
                } else {
                    return <Redirect to={"/thirdpartysignin"} />;
                }
                //} else if(window.location.pathname === "/linkedin") {
                //    return ( <Redirect to={'/linkedin'} /> );
                //this.props.location.pathname = "/linkedin"; // no idea why this doesn't match, but it doesn't
            } else {
                return <Redirect to={"/app/dashboard/default"} />;
            }
        } else if (location.pathname.indexOf("app/manage") > -1 && !authUser.fitPostAdminAccess) {
            // permissions check
            return <Redirect to={"/"} />;
        }

        const currentAppLocale = AppLocale["en"];

        return (
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <div id="app-main" className="app-main">
                    <Switch>
                        <RestrictedRoute authUser={authUser} path={`${match.url}app`} component={MainApp} />
                        <Route path="/oauth" component={Oauth} />
                        <Route path="/thirdpartysignin" component={ThirdPartySignin} />
                        <Route path="/signin" component={SignIn} />
                        <Route path="/signup" component={SignUp} />
                        <Route path="/linkedin" component={LinkedInPopUp} />
                        <Route path="/validatetwitterauth" component={TwitterAuth} />
                        <Route component={asyncComponent(() => import("../app/routes/extraPages/routes/404"))} />
                    </Switch>
                </div>
            </IntlProvider>
        );
    }
}

export default App;
