import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from '../util/IntlMessages';
import CircularProgress from '../components/CircularProgress'
import img from '../assets/images/logo.png';

class SignIn extends React.Component {
    constructor () {
        super ();
        this.state = {
            email: '',
            password: ''
        }

        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount () {
        // leave if already signed in
        if (localStorage.getItem('user_info') !== null) {
            this.props.history.push ('/');
        }
    }

    handleLogin() {

        // validate user here


        // fake it for now
        /*
        let userInfo = {
            id: 1,
            email: this.state.email,
            fullName: "Fake User",
            portalId: 1,
            roles: {
                administrator: true
            },
            settings: {
                useSystemPosts: true,
                defaultDeliveryTime: 8
            }
        }*/

        //localStorage.setItem('user_info', JSON.stringify(userInfo));
        this.props.history.push ('/');
    }

    render () {
        const {
            email,
            password
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;
        return (
            <div className="mt-3 app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Login">
                            <img src={img} alt="Login" className="img-fluid" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.email" /></h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <div className="form-group mb-3">
                                    <input
                                        placeholder="Email"
                                        onChange={(event) => this.setState ({ email: event.target.value })}
                                        defaultValue={email}
                                        className="form-control form-control-lg"
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        onChange={(event) => this.setState ({ password: event.target.value })}
                                        defaultValue={password}
                                        className="form-control form-control-lg"
                                    />
                                </div>

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button onClick={this.handleLogin}
                                        color="primary" className="text-uppercase">
                                        <IntlMessages id="appModule.signIn" />
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
                {showMessage && NotificationManager.error (alertMessage)}
                <NotificationContainer />
            </div>
        );
    }
}

export default SignIn;