import axios from './AxiosHelper';

// define public methods
export const announcementService = {    
    getActive,
    search,
    getById,
    post,
    deleteItem,
    deleteMany
};

function getActive(data) {
    // data: { portalId: 1, ids: [1,2,3] }
    return axios.postData(`/api/announcement/active`, data);
}

function search(data) {    
    return axios.postData(`/api/announcement/search`, data, true);
}

function getById(portalId, id) {
    return axios.getData(`/api/announcement/${portalId}/${id}`, true);
}

function post(data) {
    return axios.upsertData(data.id, `/api/announcement`, data, true);
}

function deleteItem(id) {
    return axios.deleteData(`/api/announcement/${id}`);
}

function deleteMany(ids) {
    return axios.deleteMultipleData(`/api/announcement`, ids);
}
