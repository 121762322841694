import React from 'react';
import {NavLink} from 'react-router-dom';
import moment from 'moment';


const NotificationItem = ({notification}) => {
    const {user, badge, userFullName, createdOn, subject} = notification;
    return (
        <li className="media">
            <div className="user-thumb mr-3">
                <img className="rounded-circle avatar-sm"
                     alt={userFullName}
                     src={user.thumbnail}
                />
                <span className="badge badge-danger rounded-circle">{badge}</span>
            </div>
            <div className="media-body">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-capitalize user-name mb-0">                        
                        <NavLink to="/app/settings/messages">
                            <span className="nav-text">{userFullName}</span>
                        </NavLink>
                    </h5>
                    <span className="meta-date"><small>{moment(createdOn).format('LL')}</small></span>
                </div>
                <p className="sub-heading">{subject}</p>
            </div>
        </li>
    );
};

export default NotificationItem;
